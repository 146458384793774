import React from "react"

const Link = ({text, onClick, id, className}) => {

    return (
        <span id={id} className={className} onClick={onClick}>{text}</span>
    )
};

export default Link;
