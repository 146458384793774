import React, {useEffect, useRef, useState} from "react"
import SEO from "../components/seo"
import "./index.css"
import Section from "../components/section";
import Link from "../components/link";
import Slide from "../components/slide";
import Contact from "../components/contact/contact";

const IndexPage = () => {

    let section1 = useRef(null);
    let section2 = useRef(null);
    let section3 = useRef(null);
    let section4 = useRef(null);

    let [slide, setSlide] = useState(1);

    let isElementInViewport = (el) => {
        let rect = el.getBoundingClientRect();
        return rect.bottom > 0 &&
            rect.top < (window.innerHeight || document.documentElement.clientHeight) / 2;
    };

    let resetLinks = () => {
        document.querySelector("#link1").classList.remove("active");
        // document.querySelector("#link2").classList.remove("active");
        document.querySelector("#link3").classList.remove("active");
        document.querySelector("#link4").classList.remove("active");
    };

    let selectLink = () => {
        resetLinks();
        if (isElementInViewport(section4.current)) {
            document.querySelector("#link4").classList.add("active");
        } else if (isElementInViewport(section3.current)) {
            document.querySelector("#link3").classList.add("active");
        } /*else if (isElementInViewport(section2.current)) {
            document.querySelector("#link2").classList.add("active");
        } */else if (isElementInViewport(section1.current)) {
            document.querySelector("#link1").classList.add("active");
        }

        if (isElementInViewport(section1.current)) {
            document.querySelector("header").classList.remove("header2");
        } else {
            document.querySelector("header").classList.add("header2");
        }
    };

    let throttle = (callback) => {
        let timeout;
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => callback(), 50)
        }
    };


    useEffect(() => {
        let callback = throttle(selectLink);
        window.addEventListener("scroll", callback);

        let sliderInterval = setInterval(() => {
            slide++;
            if (slide > 4) {
                slide = 1;
            }
            setSlide(slide);
        }, 5000);

        return () => {
            window.removeEventListener("scroll", callback);
            clearInterval(sliderInterval);
        };
    }, []);


    let scrollIntoView = (selector, offset = 0) => {
        window.scroll({left: 0, top: document.querySelector(selector).offsetTop - offset, behavior: 'smooth'});
    };

    let scrollTo = (elementId) => () => {
        scrollIntoView('#' + elementId, document.querySelector('header').offsetHeight - 55)
    };

    return <>
        <header>

            <div>
                <Link id="link1" className={"link active"} onClick={scrollTo("section1")} text="Home"/>
                {/*<Link id="link2" className={"link"} onClick={scrollTo("section2")} text="About"/>*/}
                <Link id="link3" className={"link"} onClick={scrollTo("section3")} text="Services"/>
                <Link id="link4" className={"link"} onClick={scrollTo("section4")} text="Contact"/>
            </div>

        </header>

        <main>

            <SEO title="Westway Barbell Club"/>
            <Slide id="section1" className="section" slideNr={slide} ref={section1}/>

            {/*<Section id="section2" className="section" ref={section2}/>*/}

            <Section id="section3" className="section" ref={section3}>

                <div className="services-title">
                    <h1>Coaching options</h1>
                    <h1>Coaching options</h1>
                </div>
                <div className={"services"}>
                    <div className={"service"}>
                        One to one Sessions
                    </div>
                    <div className={"service"}>
                        Westway Team sessions
                    </div>
                    <div className={"service"}>
                        Seminars for Groups
                    </div>
                    <div className={"service"}>
                        Beginners and Advance courses for individuals
                    </div>
                    <div className={"service"}>
                        Strength and Conditioning Courses
                    </div>
                </div>
            </Section>

            <Contact id="section4" className="section" ref={section4}/>

        </main>
        <footer>
            <span>© {new Date().getFullYear()},{` `} Westway Barbell Club</span>
        </footer>

    </>

};

export default IndexPage
