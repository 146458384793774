import React, {forwardRef} from "react"

const Section = ({id, className, children}, ref) => {

    return (
        <div ref={ref} id={id} className={className}>{children}</div>
    )
};

export default forwardRef(Section);
