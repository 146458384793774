import React, {forwardRef} from "react";
import GoogleMapReact from 'google-map-react';
import './contact.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope, faMapMarkerAlt, faMobileAlt, faPhone} from '@fortawesome/free-solid-svg-icons';
import {faFacebookF, faInstagram} from '@fortawesome/free-brands-svg-icons';


const Contact = ({id, className, children}, ref) => {

    const API_KEY = "AIzaSyD8gcYekhXuY39XyTVzEpaM-dLvJhILlkg";

    const center = {
        lat: 51.518620,
        lng: -0.208113
    }
    const zoom = 17;

    const Marker = props => {
        return <>
            <div className="pin"></div>
            <div className="pulse"></div>
        </>
    }

    return (
        <div ref={ref} id={id} className={className}>

            <div className={"contact-top"}>
                <div className={"contact-title"}>Contact Us</div>
                <div>
                    Want to get in touch? We'd love to hear from you. Here's how you can reach us...
                </div>
            </div>

            <div className={"contact-bottom"}>

                <div className={"contact-map"} style={{height: '300px', width: '500px'}}>
                    <GoogleMapReact
                        bootstrapURLKeys={{key: API_KEY}}
                        defaultCenter={center}
                        defaultZoom={zoom}
                    >
                        <Marker lat={center.lat} lng={center.lng}/>
                    </GoogleMapReact>
                </div>

                <div className={"contact-details"}>

                    <div className={"contact-row"}>
                        <FontAwesomeIcon size={"2x"} icon={faMapMarkerAlt} className={"contact-left-icon"}/>
                        <span className={"contact-label"}>Address:</span>
                        3-5 Thorpe Close, London W10 5XL
                    </div>

                    <div className={"contact-row"}>
                        <FontAwesomeIcon size={"2x"} icon={faPhone} className={"contact-left-icon"}/>
                        <span className={"contact-label"}>Phone:</span>
                        02089602221
                    </div>

                    <div className={"contact-row"}>
                        <FontAwesomeIcon size={"2x"} icon={faMobileAlt} className={"contact-left-icon"}/>
                        <span className={"contact-label"}>Mobile:</span>
                        07790764726
                    </div>

                    <div className={"contact-icons"}>
                        <a href={"mailto:info@westwaybarbellclub.com"}>
                            <FontAwesomeIcon size={"3x"} icon={faEnvelope} className={"contact-icon"}/>
                        </a>

                        <a href={"https://www.facebook.com/westwaybarbellclub/"} target="_blank">
                            <FontAwesomeIcon size={"3x"} icon={faFacebookF} className={"contact-icon"}/>
                        </a>

                        <a href={"https://www.instagram.com/westwaybarbellclub/"} target="_blank">
                            <FontAwesomeIcon size={"3x"} icon={faInstagram} className={"contact-icon"}/>
                        </a>
                    </div>

                </div>
            </div>

        </div>
    )
};

export default forwardRef(Contact);
