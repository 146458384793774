import React, {forwardRef, useEffect, useState} from "react"
import Slide1 from "../images/slide1.jpg";
import Slide2 from "../images/slide2.jpg";
import Slide3 from "../images/slide3.jpg";
import Slide4 from "../images/slide4.jpg";

const Slide = ({id, className, slideNr}, ref) => {


    const slideText1 = <span>
        We run olympic weightlifting as our primary coaching Method, <br/>
            it is the foundation of strength and conditioning for all the sports.<br/>
            It is also one of the greatest ways to build a good biomechanical foundation in your body.
    </span>;
    const slideText2 = <p>
        Everyone is welcome to take part in olympic weightlifting <br/>
        as we try to understand your needs before we can attempt any big lifts. <br/>
        There is always that mobility and flexibility that limits your daily routine,<br/>
        even in advance athletes who are trying to find their maximum ability <br/>
        to reach their own goals.<br/>
    </p>;
    const slideText3 = <p>
        Training with us will need your full attention and full commitment when you start the sessions.<br/>
        Optimizing your time and your mind is an essential part to reaching your goal, <br/>
        this will be our main aim for you reaching your full potential. <br/>
        The coaches’ strengths and core values are in this area. <br/>
    </p>;
    const slideText4 = <p>
        Our training programs and coaching sessions address all the limitations, <br/>
        with the correct approach we will eliminate all the faults <br/>
        and work together to correct your posture.
    </p>;

    const [slideText, setSlideText] = useState(slideText1);


    let changeSlide = (slideNumber) => {
        let slide;
        switch (slideNumber) {
            case 1:
                slide = Slide1;
                setSlideText(slideText1);
                break;
            case 2:
                slide = Slide2;
                setSlideText(slideText2);
                break;
            case 3:
                slide = Slide3;
                setSlideText(slideText3);
                break;
            case 4:
                slide = Slide4;
                setSlideText(slideText4);
                break;
            default:
                throw new Error("Invalid value")
        }
        document.querySelector("#section1").style.backgroundImage = `url(${slide})`;
    };


    useEffect(() => {
        if (slideNr) {
            changeSlide(slideNr)
        }
    }, [slideNr, changeSlide]);


    return (
        <div ref={ref} id={id} className={className}>
            <div className="slideText">
            {slideText}
            </div>
        </div>
    )
};

export default forwardRef(Slide);
